// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Slab:wght@100..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @tailwind base;
@tailwind components;
@tailwind utilities; */

:root {
  --blue: #002677;
  --light-blue: #199bfc;
  --yellow: #f4da40;
  --red: #c8102e;
  --black: #25282a;
  --gray: #d9d9d6;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;sBAEsB;;AAItB;EACE,eAAe;EACf,qBAAqB;EACrB,iBAAiB;EACjB,cAAc;EACd,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB","sourcesContent":["/* @tailwind base;\n@tailwind components;\n@tailwind utilities; */\n\n@import url(\"https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Slab:wght@100..900&display=swap\");\n\n:root {\n  --blue: #002677;\n  --light-blue: #199bfc;\n  --yellow: #f4da40;\n  --red: #c8102e;\n  --black: #25282a;\n  --gray: #d9d9d6;\n}\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
